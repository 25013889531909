// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react'

export const IconScroll = () => (
  <svg width="100%" height="100%" viewBox="0 0 10 139" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Main" transform="translate(-35.000000, -570.000000)">
        <g id="Group" transform="translate(32.000000, 570.000000)">
          <g id="Group-3" transform="translate(7.500000, 20.500000) rotate(-180.000000) translate(-7.500000, -20.500000) " fill="#212121" font-family="Nunito-SemiBold, Nunito" font-size="10.0906433" font-weight="500" letter-spacing="0.0567598676">
            <text id="SCROLL" transform="translate(7.064327, 20.722953) rotate(-90.000000) translate(-7.064327, -20.722953) ">
              <tspan x="-12.9356725" y="23.7229532">SCROLL</tspan>
            </text>
          </g>
          <path id="Line-2" d="M8,52 L8,130 L12,130 L7.5,139 L3,130 L7,130 L7,52 L8,52 Z" fill="#000000" fill-rule="nonzero"></path>
        </g>
      </g>
    </g>
  </svg>
)
