import React from 'react';
import SEO from '../components/SEO';
import HowHelp from '../components/HowHelp';
import Hero from '../components/Hero';
import WhatDo from '../components/WhatDo';
import WhatDid from '../components/WhatDid';
import WeKnow from '../components/WeKnow';
import HireUsBase from '../components/HireUsBase';
import Footer from '../components/Footer';
import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout>
    <Hero />
    <SEO />
    <HowHelp />
    <WhatDo />
    <WhatDid />
    <WeKnow />
    <HireUsBase />
    <Footer />
  </Layout>
);

export default IndexPage;
