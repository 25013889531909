import React from 'react';
import {graphql, Link, useStaticQuery} from 'gatsby';
import styled from '@emotion/styled';
import { Flex, Box } from '@rebass/grid/emotion';
import Content from './Content';
import Title from './Title';

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  text-align: center;
  padding: 0 0 7.5rem 0;
`;

const FlexBox = styled(Flex)`
  flex-wrap: wrap;
  margin: 8.4rem 0 0 0;
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    display: block;
    margin: 5.2rem 0 0 0;
  }
`;

const Col = styled.div`
  max-width: 30.3333%;
  flex: 1 0 30.3333%;
  margin: 0 4.5% 0 0;
  text-align: left;
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    max-width: 320px;
    width: 100%;
    margin: 0 auto 5rem auto;
  }
  h3 {
    font-size: ${props => props.theme.fontSize.heading2};
    line-height: ${props => props.theme.lineHeight.heading2};
    color: ${props => props.theme.colors.violet};
    border-left: 0.5rem solid ${props => props.theme.colors.lightgreen};
    padding: 0 0 0 1.6rem;
  }
  div {
    ul {
      margin: 4.2rem 0 0 0;
      list-style: none;
      @media (max-width: ${props => props.theme.breakpoint.m}) {
        margin: 2.1rem 0 0 0;
      }
      li {
        padding: 0 0 0 2.3rem;
        color: ${props => props.theme.colors.colorText};
        font-size: ${props => props.theme.fontSize.textBody};
        line-height: ${props => props.theme.lineHeight.textBody};
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 1.9rem;
          left: 0;
          width: 6px;
          height: 6px;
          display: block;
          border-radius: 100%;
          background-color: ${props => props.theme.colors.pink};
        }
      }
    }
  }
  a {
    display: inline-block;
    font-size: ${props => props.theme.fontSize.textSmall};
    margin: 4.5rem 0 0 2.3rem;
    color: ${props => props.theme.colors.colorText};
    position: relative;
    transition: 0.5s;
    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 2;
      bottom: -4px;
      transition: 0.5s;
      width: 0%;
          height: 2px;
          background: ${props => props.theme.colors.pink};
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: -4px;
          width: 100%;
          height: 2px;
          background: ${props => props.theme.colors.lightgreen};
        }
        &:hover {
          &:before {
            width: 100%;
          }
        }
  }
  :nth-child(3n) {
    margin: 0;
    @media (max-width: ${props => props.theme.breakpoint.m}) {
      margin: 0 auto 5rem auto;
    }
  }
`;

const WhatDo = () => {
  const data = useStaticQuery(query);
  const { whatdo_title_block, whatdo_group } = data.allPrismicHomePage.edges[0].node.data;
  return (
    <Wrapper>
      <Content>
        <Title dangerouslySetInnerHTML={{ __html: whatdo_title_block.text }} />
        <FlexBox>
          {whatdo_group.map(s => (
            <Col>
              <h3 dangerouslySetInnerHTML={{ __html: s.title.text }} />
              <div dangerouslySetInnerHTML={{ __html: s.items.html }} />
              <Link to={`/${s.link.document[0].uid}`}>Learn more</Link>
            </Col>
          ))}
        </FlexBox>
      </Content>
    </Wrapper>
  );
};

const query = graphql`
  query WhatDoQuery {
    allPrismicHomePage {
      edges {
        node {
          data {
            whatdo_title_block {
              text
            }
            whatdo_group {
              items {
                html
              }
              title {
                text
              }
              link {
                document {
                  uid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default WhatDo;
