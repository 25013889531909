import React from 'react';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import { graphql, useStaticQuery } from 'gatsby';
import Header from './Header';
import Content from './Content';
import { IconScroll } from './Icons';
import ImageBase from '../slices/ImageBase';
import SpecializationLinks from './SpecializationLinks';

const Wrapper = styled(Flex)`
  padding: 0 0 0 0;
  flex-direction: column;
  font-size: 1rem;
`;

const Img = styled.div`
  text-align: center;
  margin: 0 -20px;
  position: relative;
  padding: 5em 0 5em;
  .heroImgMobile {
    display: none;
  }

  @media (max-width: ${props => props.theme.breakpoint.xl}) {
    position: static;
    margin: 0;
  }
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    padding: 1em 0 1em;
    .heroImgMobile {
      display: block;
      max-width: 350px;
      margin: 0 auto;
      padding: 0 15px;
    }
    .heroImgDesktop {
      display: none;
    }
  }
`;

const ImgWrapper = styled.div`
  position: relative;
  flex: 1 0;
  display: flex;
  align-items: center;
`;

const ImgWrapperScroll = styled.div`
  position: relative;
  width: 100%;
`;

const FlexReset = styled.div`
  width: 100%;
`;

const ScrollStyled = styled.div`
  width: 15px;
  height: 150px;
  position: absolute;
  left: -8em;
  bottom: 0;
  @media (max-width: ${props => props.theme.breakpoint.xl}) {
    left: 17px;
  }
  @media (max-width: ${props => props.theme.breakpoint.ll}) {
    left: 2px;
  }
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    top: 0;
  }
`;

const Specializing = styled.div`
  line-height: 3.8em;
  margin: 5rem 0;
  @media (max-height: 700px) {
    margin: 2em 0 5em 0;
  }
  .specializingText {
    font-weight: 400;
    font-size: 2.9em;
    margin: 0 0 0.4px 0;
    @media (max-width: ${props => props.theme.breakpoint.xs}) {
      font-size: 2.8em;
    }
  }
  ul {
    padding: 0;
    list-style: none;
    margin: 0;
    li {
      display: inline-block;
      margin: 0;
      line-height: 1.2em;
      font-weight: 900;
      font-size: 2.9em;
      margin: 0 0.8rem 0 0;
      @media (max-width: ${props => props.theme.breakpoint.mx}) {
        font-size: 2.3em;
      }
      a {
        color: ${props => props.theme.colors.colorText};
        margin: 0;
        font-weight: 900;
        display: inline-block;
        position: relative;
        &:before {
          content: '';
          display: block;
          position: absolute;
          z-index: 2;
          bottom: -3px;
          transition: 0.5s;
          width: 0%;
          height: 2px;
          background: ${props => props.theme.colors.pink};
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: -3px;
          width: 100%;
          height: 2px;
          background: ${props => props.theme.colors.lightgreen};
        }
        &:hover {
          &:before {
            width: 100%;
          }
        }
      }
      &:after {
        content: ',';
      }
      &:last-child {
        margin: 0;
        &:after {
          content: '.';
        }
      }
    }
  }
`;

const Hero = () => {
  const data = useStaticQuery(query)
  const { hero_title, hero_image, hero_image_mobile } = data.allPrismicHomePage.edges[0].node.data
  const { specializations } = data.allPrismicSpecializationLinks.edges[0].node.data
  return (
    <Wrapper>
      <Header />
      <Content>
        <Specializing>
          <p className="specializingText">{hero_title.text}</p>
          <SpecializationLinks specializations={specializations} />
        </Specializing>
      </Content>
      <ImgWrapper>
        <ImgWrapperScroll>
          <Content>
            <FlexReset>
              <Img>
                <ImageBase className="heroImgDesktop" alt={hero_image.alt} localFile={hero_image.localFile} />
                <div className="heroImgMobile">
                  <ImageBase alt={hero_image_mobile.alt} localFile={hero_image_mobile.localFile} />
                </div>
                <ScrollStyled>
                  <IconScroll />
                </ScrollStyled>
              </Img>
            </FlexReset>
          </Content>
        </ImgWrapperScroll>
      </ImgWrapper>
    </Wrapper>
  );
};

const query = graphql`
  query HeroQuery {
    allPrismicHomePage {
      edges {
        node {
          data {
            hero_image {
              url
              alt
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                  sizes(maxWidth: 700, quality: 90) {
                    ...GatsbyImageSharpSizes_withWebp_noBase64
                  }
                }
              }
            }
            hero_image_mobile {
              url
              alt
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                  sizes(maxWidth: 700, quality: 90) {
                    ...GatsbyImageSharpSizes_withWebp_noBase64
                  }
                }
              }
            }
            hero_title {
              text
            }
          }
        }
      }
    }
    allPrismicSpecializationLinks {
      edges {
        node {
          data {
            specializations {
              specialization {
                document {
                  data {
                    name
                  }
                  uid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Hero;
