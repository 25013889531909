import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from '@emotion/styled';
import Content from './Content';
import Title from './Title';
import ProjectItem from './ProjectItem';

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const LinkAll = styled(Link)`
  margin: 0 0 3.6rem 0;
  font-size: ${props => props.theme.fontSize.textSmall};
  line-height: 1;
  font-weight: 500;
  color: ${props => props.theme.colors.colorText};
  border-bottom: 0.2px solid ${props => props.theme.colors.lightgreen};
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    margin: 3.8rem 0 0 2.3rem;
    font-size: 2.1rem;
    display: inline-block;
  }
`;

const Projects = styled.div`
  display: flex;
  margin: 0 0 7.5rem 0;
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    flex-direction: column;
  }
`;

const WhatDid = () => {
  const data = useStaticQuery(query);
  const {
    projects: { edges: projectsEdges },
    title: { edges: node },
  } = data;
  const { what_did_title_block } = node[0].node.data;
  return (
    <Wrapper>
      <Content>
        <Title>{what_did_title_block.text}</Title>
        <Projects>
          {projectsEdges.map(project => (
            <ProjectItem key={project.node.uid} {...project.node} />
          ))}
        </Projects>
      </Content>
    </Wrapper>
  );
};

export default WhatDid;

const query = graphql`
  query WhatDid {
    projects: allPrismicProjects {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            client {
              text
            }
            description {
              text
            }
            previewi_img {
              url
            }
            tech_stack {
              technology {
                id
                document {
                  uid
                  data {
                    name
                  }
                }
              }
            }
            project_type {
              specialization {
                id
                document {
                  uid
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
    title: allPrismicHomePage {
      edges {
        node {
          data {
            what_did_title_block {
              text
            }
          }
        }
      }
    }
  }
`;
