import React from 'react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import Content from './Content';
import Title from './Title';
import ImageBase from '../slices/ImageBase';

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 0 0 7.5rem 0;
`;
const Technologies = styled.div`
  display: flex;
  text-align: left;
  @media (max-width: ${props => props.theme.breakpoint.sl}) {
    align-items: center;
  }
`;

const Image = styled(ImageBase)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  @media (max-width: ${props => props.theme.breakpoint.sl}) {
    display: none;
  }
`;

const TechnologiesList = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: ${props => props.theme.breakpoint.sl}) {
    align-items: center;
    width: 100%;
  }
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    margin: 5.2rem 0 0 0;
  }
  ul {
    margin: 0;
    list-style: none;
    @media (max-width: ${props => props.theme.breakpoint.sl}) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    li {
      margin: 0;
      padding: 0 0 0 2.3rem;
      position: relative;
      color: ${props => props.theme.colors.colorText};
      font-size: ${props => props.theme.fontSize.textBody};
      line-height: ${props => props.theme.lineHeight.textBody};
      font-weight: 400;
      @media (max-width: ${props => props.theme.breakpoint.m}) {
        font-size: 2.75rem;
        line-height: 4.8rem;
      }
      &:before {
        content: '';
        position: absolute;
        top: 2.2rem;
        left: 0;
        width: 6px;
        height: 6px;
        display: block;
        border-radius: 100%;
        background-color: ${props => props.theme.colors.pink};
      }
    }
  }
`;

const WeKnow = () => {
  const data = useStaticQuery(query);
  const { weknow_items, weknow_title_block, weknow_left_img } = data.allPrismicHomePage.edges[0].node.data;
  return (
    <Wrapper>
      <Content>
        <Title>{weknow_title_block.text}</Title>
        <Technologies>
          <Image className="width" alt={weknow_left_img.alt} localFile={weknow_left_img.localFile} />
          <TechnologiesList>
            <div dangerouslySetInnerHTML={{ __html: weknow_items.html }} />
          </TechnologiesList>
        </Technologies>
      </Content>
    </Wrapper>
  );
};

export default WeKnow;

const query = graphql`
  query WeKnowQuery {
    allPrismicHomePage {
      edges {
        node {
          data {
            weknow_items {
              html
            }
            weknow_title_block {
              text
            }
            weknow_left_img {
              alt
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                  sizes(maxWidth: 700, quality: 90) {
                    ...GatsbyImageSharpSizes_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
